"use client";

// imports
import * as Sentry from "@sentry/nextjs";
import { useEffect } from "react";

// components
import Error50X from "./Error50X/Error50X";
export default function GlobalError({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <Error50X data-sentry-element="Error50X" data-sentry-source-file="global-error.tsx" />
      </body>
    </html>;
}