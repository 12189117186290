import React from 'react';
import Link from 'next/link';
import PBSLogotype from '/public/svg/pbs-logotype-white--blue-fill-face.svg';
export default function Error50X() {
  const error50XStyle: React.CSSProperties = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    color: '#eef0f5'
  };
  const error50XBlueLine: React.CSSProperties = {
    width: '100vw',
    height: '8px',
    backgroundColor: '#2638C4',
    position: 'fixed',
    insetBlockStart: '0'
  };
  const error50XPbsLogoStyle: React.CSSProperties = {
    position: 'absolute',
    insetBlockStart: '20px',
    insetInlineStart: 'var(--spacing-inline)'
  };
  const h1Style: React.CSSProperties = {
    fontSize: 'clamp(16px, 1.6vw + 1em, 30px)',
    fontWeight: 700,
    lineHeight: 1.1
  };
  const pStyle: React.CSSProperties = {
    fontSize: 'clamp(14px, 1vw + 0.5em, 16px)',
    fontWeight: 400,
    lineHeight: 1.5
  };
  const svgStyle: React.CSSProperties = {
    width: 'auto',
    height: 'var(--navigation-image-height)'
  };

  // Inline styles cannot include media queries
  // so adding responsive styles in a style block instead
  const responsiveStyles = `
    body {
      padding: 0;
    }
    .error-content {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 84vw;
      text-align: center;
    }
    @media (min-width: 768px) {
      .error-content {
        width: 50vw;
      }
    }
    @media (min-width: 1024px) {
      .error-content {
        gap: 40px;
        width: 40vw;
      }
    }
    @media (min-width: 1440px) {
      .error-content {
        width: 30vw;
        max-width: 474px;
      }
    }
  `;
  return <>
      <div style={error50XStyle}>
        <div style={error50XBlueLine}></div>
        <Link href="/" style={error50XPbsLogoStyle} data-sentry-element="Link" data-sentry-source-file="Error50X.tsx">
          <PBSLogotype style={svgStyle} data-sentry-element="PBSLogotype" data-sentry-source-file="Error50X.tsx" />
        </Link>
        <div className="error-content">
          <h1 style={h1Style}>PBS is Temporarily Unavailable</h1>
          <p style={pStyle}>
            We’re sorry, PBS.org is currently experiencing connectivity issues. Please try refreshing the page or check back shortly. We will be back online as soon as possible.
          </p>
        </div>
      </div>
      <style jsx>
        {responsiveStyles}
      </style>
    </>;
}